<!--DivNoData:无数据 2024/08/27 20:24:31 huangmx-->

<template>
  <div class="div-no-data">
    <div class="no-data">
      <img src="@/assets/images/nodata.png" alt="">
      <div class="tip">{{ desc }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DivNoData',
  props: {
    desc: {
      type: String,
      default: function () {
        return this.$t('nodata')
      }
    }
  },
  data () {
    return {
      tip: 'Hi, 新的组件'
    }
  }
}
</script>

<style scoped>
.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-data img {
  width: 300px;
  height: 300px;
}
</style>
