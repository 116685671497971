<!--AttentionListDialog:关注和粉丝列表弹窗 2024/08/25 09:04:42 huangmx-->

<template>
  <div class="attention-list-dialog">
    <el-dialog :visible.sync="localVisible" v-if="localVisible" @close="handleClose" :close-on-click-modal="false"
      :close-on-press-escape="false" :destroy-on-close=true width="818px">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="$t('attention')" name="attention">
          <div class="container">
            <div-no-data v-if="total === 0" :desc="$t('noAttention')"></div-no-data>
            <ul class="fanme" v-else>
              <li class="atten-list-li" v-for="(item, index) in attentionList" :key="'attention_' + index">
                <head-common :item="item"></head-common>
              </li>
            </ul>
            <el-pagination v-if="total > pageSize" class="pagination" background layout="prev, pager, next"
              :page-size="pageSize" :total="total" @current-change="pageChange">
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('fans')" name="fans">
          <div class="container">
            <div-no-data v-if="total === 0" :desc="$t('noFans')"></div-no-data>
            <ul class="fanme" v-else>
              <li class="atten-list-li" v-for="(item, index) in fansList" :key="'fans_' + index">
                <head-common :item="item"></head-common>
                <el-button class="right-btn" @click="attention(item)" size="mini" plain>
                  {{ item.attention ? $t('attentioned') : '+ ' + $t('attention') }}</el-button>
              </li>
            </ul>
            <el-pagination v-if="total > pageSize" class="pagination" background layout="prev, pager, next"
              :page-size="pageSize" :total="total" @current-change="pageChange">
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>
<script>
import HeadCommon from '@/views/components/HeadCommon.vue'
import DivNoData from '@/views/components/DivNoData.vue'
import { interactionApi, trackApi } from '@/utils/api'

export default {
  name: 'AttentionListDialog',
  components: {
    HeadCommon,
    DivNoData
  },
  props: {
    userId: {
      type: String,
      default: function () {
        return ''
      }
    },
    visible: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    activeType: {
      type: String,
      default: function () {
        return 'attention'
      }
    },
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      activeName: this.activeType,
      attentionList: [], // 关注列表
      fansList: [], // 粉丝列表
      localVisible: false,
      currentPage: 1,
      pageSize: 5,
      total: 0
    }
  },
  watch: {
    visible: function (newValue) {
      this.localVisible = newValue
      if (this.localVisible) {
        this.fetchData()
      }
    },
    activeType: function (newValue) {
      this.activeName = newValue
    }
  },
  created () {
    this.localVisible = this.visible
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData: function () {
      if (this.activeType === 'attention') {
        this.getAttentions()
      } else {
        this.getFans()
      }
    },
    handleClick: function (tab, event) {
      console.log(tab, event)
      this.currentPage = 1
      if (tab.name === 'attention') {
        this.getAttentions()
      } else {
        this.getFans()
      }
    },
    handleClose: function () {
      this.localVisible = false
      this.$emit('update:visible', false)
    },
    pageChange: function (currentPage) {
      console.log('change page to', currentPage)
      this.currentPage = currentPage
      if (this.activeName === 'attention') {
        this.getAttentions()
      } else {
        this.getFans()
      }
    },
    getAttentions: function () {
      if (!this.userId) {
        console.log('no user id')
        return
      }
      interactionApi.getMyAttentions({
        userId: this.userId,
        currentPage: this.currentPage,
        pageSize: this.pageSize
      }).then(resp => {
        if (resp.httpStatus === 200) {
          this.attentionList = resp.data
          this.total = resp.totalSize
        }
      })
    },
    getFans: function () {
      interactionApi.getFans({
        userId: this.userId,
        currentPage: this.currentPage,
        pageSize: this.pageSize
      }).then(resp => {
        if (resp.httpStatus === 200 && resp.data) {
          if (resp.data.length > 0) {
            resp.data.map(item => {
              this.$set(item, 'attention', item.interactionRecordId && item.interactionRecordId > 0)
            })
          }
          this.fansList = resp.data
          this.total = resp.totalSize
        }
      })
    },
    attention: function (item) {
      if (item.attention) {
        // 取消关注
        item.attention = false
        this.delInteraction(item.interactionRecordId)
      } else {
        // 关注
        item.attention = true
        this.addInteraction(item.id, item)
        // 关注用户 埋点
        trackApi.articleTrack('ATTENTION_USER', null, item.id, null)
      }
    },
    addInteraction: function (targetId, item) {
      const params = {
        targetId: targetId,
        targetType: 'USER',
        interactionType: 'ATTENTION'
      }
      interactionApi.createInteraction(params).then(resp => {
        console.log('添加互动成功', resp)
        if (resp.httpStatus === 200) {
          item.interactionRecordId = resp.data
        }
      })
    },
    delInteraction: function (interactionId) {
      if (!interactionId) {
        return
      }
      interactionApi.delInteraction(interactionId).then(resp => {
        console.log('删除互动', resp)
      })
    }
  }
}
</script>

<style scoped>
.xx-AttentionListDialog {
  color: brown;
}

.attention-list-dialog>>>.el-dialog__wrapper .el-dialog {
  border-radius: 14px;
  padding: 5px;
}

.attention-list-dialog>>>.el-tabs__item {
  font-size: 18px;
}

.fanme {
  padding: 0px 20px 0px 20px;
}

.atten-list-li {
  display: flex;
  justify-content: space-between;
}

.atten-list-li:not(:last-child) {
  padding: 10px 0;
  border-bottom: 1px dashed rgb(185, 185, 185);
}

.right-btn {
  height: 24px;
  margin-top: 18px;
}

.container {
  margin-bottom: 20px;
}

.pagination {
  margin-top: 10px;
}
</style>
