<!--index:Hi, 用户中心 2024/07/03 22:51:52 YuAng-->

<template>
  <div class="user-index">
    <div class="person-title-container">
      <div class="people-top page-center-content">
        <div class="people-top-content">
          <head-img :user="user" border size="84px"></head-img>
          <div class="people-top-content-right">
            <span class="user-name">{{ user.name }}</span>
            <div class="user-intro">{{ user.introduction }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-content page-center-content">
      <div class="main-content">
        <div class="main-tab">
          <ul>
            <li @click="tabChange(item)" v-for="item in routeTabs" :key="item.value"
              :class="{ active: $route.path === '/user' + item.value }">
              <span>{{ item.name }}</span>
              <i></i>
            </li>
          </ul>
          <el-button v-if="isTa" @click="attention" class="follow-btn" size="mini" type="primary" plain>{{
            userInteraction.attention ? $t('attentioned') : '+ ' + $t('attention') }}</el-button>
        </div>
        <div class="w-content">
          <router-view></router-view>
        </div>
      </div>
      <div class="side-content">
        <div class="setting">
          <a v-if="isTa">
            <i class="el-icon-setting"></i>
            <span> {{ $t('hisSetting') }}</span>
          </a>
          <a v-if="!isTa" href="/user/setting">
            <i class="el-icon-setting"></i>
            <span> {{ $t('setting') }}</span>
          </a>
        </div>
        <div class="num-view">
          <h5>{{ isTa ? $t('hisSocial') : $t('mySocial') }}</h5>
          <ul>
            <li @click="showFansDialog('attention')">
              <div class="num">{{ userInteraction.attentionNum || 0 }}</div>
              <div class="text">{{ $t('attention') }}</div>
            </li>
            <li @click="showFansDialog('fans')">
              <div class="num">{{ userInteraction.fansNum || 0 }}</div>
              <div class="text">{{ $t('fans') }}</div>
            </li>
            <li @click="$router.push($router.push('/user/friendnews?id=' + user.id))">
              <div class="num">{{ userInteraction.postNum || 0 }}</div>
              <div class="text">{{ $t('po') }}</div>
            </li>
            <li>
              <div class="num">{{ userInteraction.likeNum || 0 }}</div>
              <div class="text">{{ $t('likeBtn') }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!--关注（粉丝）列表弹窗-->
    <attention-list-dialog :visible.sync="showAttentionDialog" :user-id="user.id"
      :active-type="activeType"></attention-list-dialog>
  </div>
</template>
<script>
import HeadImg from '../components/HeadImg.vue'
import AttentionListDialog from '@/views/components/AttentionListDialog.vue'

import { userApi, interactionApi, trackApi } from '@/utils/api'
export default {
  components: { HeadImg, AttentionListDialog },
  name: 'index',
  data () {
    return {
      routeTabs: [
        {
          name: this.$t('myYingDi'),
          value: '/yingdi'
        },
        {
          name: this.$t('friendDy'),
          value: '/friendnews'
        },
        {
          name: this.$t('fire'),
          value: '/fire'
        },
        {
          name: this.$t('favorite'),
          value: '/collected'
        },
        {
          name: this.$t('replyComment'),
          value: '/notify'
        },
        {
          name: this.$t('notice'),
          value: '/notice'
        }
      ],
      userInteraction: {},
      taUser: {},
      showAttentionDialog: false,
      activeType: ''
    }
  },
  computed: {
    user: function () {
      if (this.isTa) {
        return this.taUser
      }
      return this.$store.state.user
    },
    isTa: function () {
      return this.$route.query.id
    }
  },
  mounted () {
    this.setRoutes()
  },
  methods: {
    setRoutes () {
      if (this.isTa) {
        this.getUserInteractions()
        this.getByUserId()
        this.routeTabs = [
          {
            name: this.$t('hisYingDi'),
            value: '/yingdi'
          },
          {
            name: this.$t('hisFire'),
            value: '/fire'
          },
          {
            name: this.$t('hisPost'),
            value: '/friendnews'
          },
          {
            name: this.$t('hisComment'),
            value: '/notify'
          }
        ]
      } else {
        this.routeTabs = [
          {
            name: this.$t('myYingDi'),
            value: '/yingdi'
          },
          {
            name: this.$t('friendDy'),
            value: '/friendnews'
          },
          {
            name: this.$t('fire'),
            value: '/fire'
          },
          {
            name: this.$t('favorite'),
            value: '/collected'
          },
          {
            name: this.$t('replyComment'),
            value: '/notify'
          },
          {
            name: this.$t('notice'),
            value: '/notice'
          }
        ]
      }
    },
    getByUserId: function () {
      userApi.getByUserId(this.isTa).then(resp => {
        if (resp.httpStatus === 200) {
          this.taUser = resp.data
        }
      }).catch(err => {
        console.log('getByUserId error', err)
      })
    },
    getUserInteractions: function () {
      const userId = this.isTa ? this.isTa : this.$store.state.user.id
      userApi.getUserInteraction(userId).then(resp => {
        if (resp.httpStatus === 200) {
          this.userInteraction = resp.data
        }
      }).catch(err => {
        console.log('getUserInteraction error', err)
      })
    },
    attention: function () {
      if (!this.checkLogin()) {
        return
      }
      if (this.userInteraction.attention) {
        // 取消关注
        this.userInteraction.attention = false
        this.$message.success(this.$t('cancelAttention'))
        this.delInteraction(this.userInteraction.attentionInteractionRecordId)
      } else {
        // 关注
        this.userInteraction.attention = true
        this.$message.success(this.$t('attentionSuccess'))
        this.addInteraction(this.isTa, 'USER', 'ATTENTION')
        // 关注用户 埋点
        trackApi.articleTrack('ATTENTION_USER', null, this.isTa, null)
      }
    },
    addInteraction: function (targetId, targetType, interactionType) {
      const params = {
        targetId: targetId,
        targetType: targetType,
        interactionType: interactionType
      }
      interactionApi.createInteraction(params).then(resp => {
        console.log('添加互动成功', resp)
        if (resp.httpStatus === 200) {
          this.userInteraction.attentionInteractionRecordId = resp.data
        }
      })
    },
    delInteraction: function (interactionId) {
      if (!interactionId) {
        return
      }
      interactionApi.delInteraction(interactionId).then(resp => {
        console.log('删除互动', resp)
      })
    },
    tabChange (item) {
      const query = {}
      if (this.isTa) {
        query.id = this.isTa
      }
      this.$router.push({ path: '/user' + item.value, query })
    },
    faceError () {
      this.user.face = require('@/assets/images/default_avatar.png')
    },
    checkLogin: function () {
      if (!this.$store.state.user || this.$store.state.user.name === '未登录') {
        this.$store.state.loginVisible = true
        return false
      }
      return true
    },
    showFansDialog: function (type) {
      this.showAttentionDialog = true
      this.activeType = type
    }
  },
  watch: {
    $route: function () {
      this.setRoutes()
    }
  }
}
</script>

<style scoped>
.user-index {
  position: relative;
  padding-top: 300px;
}

.person-title-container {
  background-image: url("../../assets/images/default_background.jpg");
  height: 646px;
  background-color: rgba(120, 120, 120, 1);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}

.person-title-container:before {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  content: "";
  background: rgba(0, 0, 0, 0.5);
}

.person-title-container:after {
  position: absolute;
  bottom: 0;
  width: 100%;
  content: "";
  height: 202px;
  background: linear-gradient(180deg,
      hsla(0, 0%, 97.6%, 0),
      hsla(0, 0%, 97.6%, 0.37) 20%,
      hsla(0, 0%, 97.6%, 0.64) 40%,
      hsla(0, 0%, 97.6%, 0.87) 65%,
      hsla(0, 0%, 97.6%, 0.95) 80%,
      #f9f9f9);
}

.people-top {
  height: 646px;
  color: white;
}

.people-top-content {
  width: 670px;
  margin: 100px auto;
  display: flex;
  align-items: center;
}

.user-name {
  font-size: 18px;
}

.bottom-content {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}

.main-content {
  width: 778px;
  min-height: 100px;
}

.side-content {
  width: 282px;
  min-height: 100px;
}

.main-tab,
.setting {
  height: 64px;
  background-color: white;
  border-radius: 14px;
  padding-left: 20px;
  width: 100%;
}

.main-tab li {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  float: left;
  font-size: 16px;
  line-height: 24px;
  line-height: 1;
  margin-right: 20px;
  text-align: center;
  color: rgb(120, 120, 120);
  margin-top: 22px;
}

.main-tab li i {
  content: "";
  background-color: transparent;
  border-radius: 1px;
  display: inline-block;
  height: 2px;
  margin-top: 6px;
  width: 36px;
}

.main-tab li.active {
  font-weight: 700;
  color: rgb(17, 24, 39);
}

.main-tab li.active i {
  background-color: rgb(17, 24, 39);
}

.w-content {
  padding-top: 20px;
}

.setting {
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  line-height: 24px;
  line-height: 1;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 20px;
}

.setting span,
.setting i {
  color: rgba(19, 128, 240);
}

.side-content .num-view {
  padding: 20px;
}

.side-content .num-view {
  background-color: white;
  border-radius: 14px;
  margin-bottom: 20px;
  width: 282px;
}

.num-view ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
}

.num-view h5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: rgb(50, 50, 50);
}

.num-view ul li {
  cursor: pointer;
  margin-bottom: 30px;
  text-align: center;
  width: 40%;
}

.num-view ul li .num {
  font-size: 20px;
  line-height: 28px;
  color: rgb(50, 50, 50);
}

.side-content .num-view>ul li .text {
  font-size: 16px;
  line-height: 24px;
  color: rgb(120, 120, 120);
}

.people-top-content-right {
  margin-left: 20px;
}

.follow-btn {
  text-align: right;
  float: right;
  margin-top: 18px;
}
</style>
